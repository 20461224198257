<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="title is-5 has-text-primary">
              <span class="icon">
                <i class="fad fa-info-circle"></i>
              </span>
              <span>
                Your upload
              </span>
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="close">Close</a>
          </div>
        </div>
        <div>
          <div class="field">
            <p class="title is-6 has-text-grey-light">Filename</p>
            <p class="subtitle is-6">{{ data.filename }}</p>
          </div>
          <div class="field">
            <p class="title is-6 has-text-grey-light">
              Uploaded date and time
            </p>
            <p class="subtitle is-6">{{ data.date.value }}</p>
          </div>
          <div class="field">
            <p class="title is-6 has-text-grey-light">Status</p>
            <p class="subtitle is-6" :class="data.status.class">
              {{ data.status.text }}
            </p>
          </div>
          <div class="field">
            <p class="title is-6 has-text-grey-light">
              Retail values completed
            </p>
            <p
              class="subtitle is-6"
              :class="[!data.rows.retail ? 'has-text-danger' : 'has-text-info']"
            >
              {{ data.rows.retail || 0 }} of {{ data.rows.total || 0 }}
            </p>
          </div>
          <div class="field">
            <p class="title is-6 has-text-grey-light">
              Trade values completed
            </p>
            <p
              class="subtitle is-6"
              :class="[
                !data.rows.trade ? 'has-text-danger' : 'has-text-primary'
              ]"
            >
              {{ data.rows.trade || 0 }} of {{ data.rows.total || 0 }}
            </p>
          </div>
          <div class="box">
            <div class="tags is-multiline">
              <span
                class="tag heading is-warning is-light is-rounded"
                v-for="(field, index) in JSON.parse(data.fields)"
                :key="index"
              >
                {{ startCase(field) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash'
export default {
  name: 'MultiDetails',
  props: {
    data: {
      type: null,
      required: true,
      default: () => null
    }
  },
  methods: {
    close() {
      this.$modal.close()
    },
    startCase(value) {
      return startCase(value)
    }
  }
}
</script>
